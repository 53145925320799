import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-website',
  templateUrl: './website.page.html',
  styleUrls: ['./website.page.scss'],
})
export class WebsitePage implements OnInit {

  public currentDate: String;


  constructor(private router: Router) { 
    this.currentDate = (new Date()).toISOString();
  }

  ngOnInit() {
    
  }

  servicios() {
    this.router.navigate(["/servicios"]);
  }

  contacto() {
    this.router.navigate(["/contacto"]);
  }

  inicio() {
    this.router.navigate(["/home"]);
  }

}

