import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.page.html',
  styleUrls: ['./mobile.page.scss'],
})
export class MobilePage implements OnInit {

  public currentDate: string;

  constructor(private router: Router) {
    this.currentDate = (new Date()).toISOString();
   }

  ngOnInit() {
  }

  servicios() {
    this.router.navigate(["/servicios"]);
  }

  contacto() {
    this.router.navigate(["/contacto"]);
  }

  inicio() {
    this.router.navigate(["/home"]);
  }


}
