import { Component } from '@angular/core';
import { ServiciosPage } from '../servicios/servicios.page';
import { ContactoPage } from '../contacto/contacto.page';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {

  public currentDate: string;

  slideOpts = {
    slidesPerView: 1.5,
    spaceBetween: 1,
    centeredSlides: true,
    loop: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      speed: 400
    }
  }

  constructor(private router: Router) {
    this.currentDate = (new Date()).toISOString();
  }

  servicios() {
    this.router.navigate(["/servicios"]);
  }

  contacto() {
    this.router.navigate(["/contacto"]);
  }

  inicio() {
    this.router.navigate(["/home"]);
  }

  website() {
    this.router.navigate(["/website"]);
  }

  mobile() {
    this.router.navigate(["/mobile"]);
  }

}
